import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocationIcon, MailIcon, PhoneIcon } from '../assets/images';
import { FaLinkedin } from 'react-icons/fa';

const ContactInformation: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      from_name: '',
      from_email: '',
      message: '',
    },
    validationSchema: Yup.object({
      from_name: Yup.string().min(2, 'Name must be at least 2 characters').required('Name is required'),
      from_email: Yup.string().email('Invalid email address').required('Email is required'),
      message: Yup.string().min(10, 'Message must be at least 10 characters').required('Message is required'),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      emailjs
          .send('service_zdcd17m', 'template_sqtdw9t', values, 'wC1r-0eHwCvylBbd9')
          .then(() => {
            toast.success('Message sent successfully!');
            resetForm();
          })
          .catch(() => {
            toast.error('Failed to send message. Please try again.');
          })
          .finally(() => {
            setLoading(false);
          });
    },
  });

  return (
      <section
          id="contact"
          style={{ background: 'linear-gradient(90deg, #0088D4 0%, #002C6E 100%)' }}
          className="py-16"
      >
        <div className="max-w-7xl mx-auto px-6 md:px-10 relative">
          <motion.div
              className="text-white text-center md:text-left mb-12 flex justify-between items-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
          >
            <h3 className="text-3xl font-bold">Let’s Bring Your Project to Life</h3>
          </motion.div>

          <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-12">
            <motion.div
                className="flex flex-col justify-center md:items-start items-center text-white space-y-4 md:h-full"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
            >
              <p className="text-base mt-2 max-w-2xl text-center md:text-left w-5/6">
                We’d love to hear from you! Reach out to explore how our solutions can elevate your business.
              </p>
              <div className="flex items-center md:justify-start">
                <img src={PhoneIcon} alt="Phone" className="w-5 h-5 mr-2" />
                <span className="text-base">+27723259580 (South Africa)</span>
              </div>
              <div className="flex items-center md:justify-start">
                <img src={PhoneIcon} alt="Phone" className="w-5 h-5 mr-2" />
                <span className="text-base">+31684435909 (Netherlands)</span>
              </div>
              <div className="flex items-center md:justify-start">
                <img src={MailIcon} alt="Mail" className="w-5 h-5 mr-2" />
                <span className="text-base">Sales@CirrusBridge.com</span>
              </div>
              <div className="flex items-center md:justify-start">
                <img src={LocationIcon} alt="Location" className="w-5 h-5 mr-2" />
                <span className="text-base">South Africa & The Netherlands</span>
              </div>

              <div className="flex items-center md:justify-start mt-4">
                <motion.a
                    href="https://www.linkedin.com/company/cirrus-bridge"
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-white text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white py-1.5 px-3 md:px-4 rounded flex items-center text-sm md:text-base transition duration-200 ease-in-out"
                >
                  <FaLinkedin className="mr-2" size={16} />
                  <span>LinkedIn</span>
                </motion.a>
              </div>
            </motion.div>

            <motion.div
                className="bg-white p-6 rounded-lg shadow-md w-full md:w-2/3 lg:w-1/2"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
            >
              <form onSubmit={formik.handleSubmit}>
                <h4 className="text-xl font-bold text-gray-800 mb-4">Contact Us</h4>
                <div className="mb-4">
                  <label htmlFor="from_name" className="block text-gray-700 mb-1">
                    Name
                  </label>
                  <motion.input
                      type="text"
                      id="from_name"
                      name="from_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.from_name}
                      whileFocus={{ scale: 1.05 }}
                      className={`w-full p-2 border rounded ${
                          formik.touched.from_name && formik.errors.from_name
                              ? 'border-red-500'
                              : 'border-gray-300'
                      }`}
                  />
                  <div className="min-h-[1rem] mt-1">
                    {formik.touched.from_name && formik.errors.from_name && (
                        <div className="text-red-500 text-sm">{formik.errors.from_name}</div>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor="from_email" className="block text-gray-700 mb-1">
                    Email
                  </label>
                  <motion.input
                      type="email"
                      id="from_email"
                      name="from_email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.from_email}
                      whileFocus={{ scale: 1.05 }}
                      className={`w-full p-2 border rounded ${
                          formik.touched.from_email && formik.errors.from_email
                              ? 'border-red-500'
                              : 'border-gray-300'
                      }`}
                  />
                  <div className="min-h-[1rem] mt-1">
                    {formik.touched.from_email && formik.errors.from_email && (
                        <div className="text-red-500 text-sm">{formik.errors.from_email}</div>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor="message" className="block text-gray-700 mb-1">
                    Message
                  </label>
                  <motion.textarea
                      id="message"
                      name="message"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.message}
                      whileFocus={{ scale: 1.05 }}
                      className={`w-full p-2 border rounded ${
                          formik.touched.message && formik.errors.message
                              ? 'border-red-500'
                              : 'border-gray-300'
                      }`}
                  />
                  <div className="min-h-[1rem] mt-1">
                    {formik.touched.message && formik.errors.message && (
                        <div className="text-red-500 text-sm">{formik.errors.message}</div>
                    )}
                  </div>
                </div>
                <motion.button
                    type="submit"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    disabled={loading}
                    style={{ background: 'linear-gradient(90deg, #0088D4 0%, #002C6E 100%)' }}
                    className={`bg-blue-900 text-white py-2 px-3 rounded w-full ${
                        loading ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-800'
                    }`}
                >
                  {loading ? 'Sending...' : 'Send Message'}
                </motion.button>
              </form>
            </motion.div>
          </div>
        </div>
        <ToastContainer />
      </section>
  );
};

export default ContactInformation;
