import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { TransparentLogo } from "../assets/images";
import { FiMenu, FiX } from "react-icons/fi";

const Navbar = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const headerHeight = 64; // Adjust this value to match your Navbar's height

    const scrollWithOffset = (el: HTMLElement) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -headerHeight; // Adjust the offset here
        window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <nav className="fixed top-0 w-full bg-white flex items-center justify-start p-4 shadow-md z-20">
            {/* Logo */}
            <HashLink
                to="/#top"
                scroll={scrollWithOffset}
                smooth
                className="flex items-center ml-6"
                onClick={() => setMobileMenuOpen(false)} // Close mobile menu if open
            >
                <img src={TransparentLogo} alt="Logo" className="h-12 mr-2" />
                <span
                    style={{
                        background: "linear-gradient(130deg, #0088D4 0%, #002C6E 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        display: "inline-block",
                    }}
                    className="text-xl font-bold"
                >
          Cirrus Bridge
        </span>
            </HashLink>

            {/* Desktop Navigation */}
            <ul className="hidden md:flex space-x-8 text-[#4A5568] ml-10">
                <li>
                    <HashLink
                        smooth
                        to="/#services"
                        scroll={scrollWithOffset}
                        className="cursor-pointer hover:text-blue-500"
                    >
                        What We Offer
                    </HashLink>
                </li>
                <li>
                    <HashLink
                        smooth
                        to="/#clients"
                        scroll={scrollWithOffset}
                        className="cursor-pointer hover:text-blue-500"
                    >
                        Trusted by Leading Brands
                    </HashLink>
                </li>
                <li>
                    <HashLink
                        smooth
                        to="/#values"
                        scroll={scrollWithOffset}
                        className="cursor-pointer hover:text-blue-500"
                    >
                        What Drives Us
                    </HashLink>
                </li>
                <li>
                    <HashLink
                        smooth
                        to="/#contact"
                        scroll={scrollWithOffset}
                        className="cursor-pointer hover:text-blue-500"
                    >
                        Let’s Connect
                    </HashLink>
                </li>
                <li>
                    <Link to="/blogs" className="cursor-pointer hover:text-blue-500">
                        Articles
                    </Link>
                </li>
            </ul>

            {/* Mobile Menu Icon */}
            <div className="md:hidden flex items-center ml-auto">
                <button
                    onClick={toggleMobileMenu}
                    className="focus:outline-none text-gray-800"
                >
                    {isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
                </button>
            </div>

            {/* Mobile Menu */}
            {isMobileMenuOpen && (
                <div className="absolute top-16 left-0 w-full bg-white shadow-lg flex flex-col items-center md:hidden">
                    <HashLink
                        smooth
                        to="/#services"
                        scroll={scrollWithOffset}
                        className="cursor-pointer py-4 text-gray-800 hover:text-blue-500 w-full text-center"
                        onClick={toggleMobileMenu}
                    >
                        What We Offer
                    </HashLink>
                    <HashLink
                        smooth
                        to="/#clients"
                        scroll={scrollWithOffset}
                        className="cursor-pointer py-4 text-gray-800 hover:text-blue-500 w-full text-center"
                        onClick={toggleMobileMenu}
                    >
                        Trusted by Leading Brands
                    </HashLink>
                    <HashLink
                        smooth
                        to="/#values"
                        scroll={scrollWithOffset}
                        className="cursor-pointer py-4 text-gray-800 hover:text-blue-500 w-full text-center"
                        onClick={toggleMobileMenu}
                    >
                        What Drives Us
                    </HashLink>
                    <HashLink
                        smooth
                        to="/#contact"
                        scroll={scrollWithOffset}
                        className="cursor-pointer py-4 text-gray-800 hover:text-blue-500 w-full text-center"
                        onClick={toggleMobileMenu}
                    >
                        Let’s Connect
                    </HashLink>
                    <Link
                        to="/blogs"
                        className="cursor-pointer py-4 text-gray-800 hover:text-blue-500 w-full text-center"
                        onClick={() => {
                            toggleMobileMenu();
                            window.scrollTo({ top: 0, behavior: "auto" });
                        }}
                    >
                        Blogs
                    </Link>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
