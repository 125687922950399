import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import { blogs } from "../Blogs/blogs.data";

const externalArticles = [
    {
        id: "external-businesstech",
        externalLink:
            "https://businesstech.co.za/news/industry-news/806521/cirrus-bridge-crafting-the-future-of-digital-consultancy-with-tailored-mobile-solutions/",
        date: "2025-01-13",
        author: "BusinessTech",
        title: "Cirrus Bridge: Crafting the Future of Digital Consultancy",
        description:
            "How Cirrus Bridge delivers tailored mobile solutions that shape tomorrow’s digital landscape.",
        image:
            "https://businesstech.co.za/news/wp-content/uploads/2025/01/Cirrus-Bridge-Header.jpg"
    },
    {
        id: "external-techcentral",
        externalLink:
            "https://techcentral.co.za/cirrus-bridge-consultancy-end-to-end/254300/",
        date: "2024-10-31",
        author: "TechCentral",
        title: "Cirrus Bridge End-to-End Consulting",
        description:
            "Exploring the power of full-spectrum consulting for modern, scalable solutions.",
        image:
            "https://techcentral.co.za/wp-content/uploads/2024/10/cirrus-bridge-1500-800.jpg"
    }
];

const BlogPage: React.FC = () => {
    // Merge both arrays
    const allArticles = [...blogs, ...externalArticles];

    // (Optional) Sort descending by date, so newest appears first:
    allArticles.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );

    return (
        <>
            <Navbar />
            <div className="pt-20 bg-gray-100 min-h-screen">
                <header className="bg-blue-900 text-white py-6">
                    <div className="max-w-7xl mx-auto px-4">
                        <h1 className="text-4xl font-bold">News & Articles</h1>
                        <p className="text-lg mt-2">
                            Stay ahead with the latest trends, expert insights, and practical tips on building
                            innovative software solutions
                            <br />
                            and scaling your business with technology.
                        </p>
                    </div>
                </header>

                <main className="py-12">
                    <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {allArticles.map((article) => {
                            // If the article has an externalLink, render <a>. Otherwise, <Link>.
                            const isExternal = !!article.externalLink;

                            if (isExternal) {
                                return (
                                    <a
                                        key={article.id}
                                        href={article.externalLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="block bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
                                    >
                                        <img
                                            src={article.image}
                                            alt={article.title}
                                            className="w-full h-60 object-cover"
                                        />
                                        <div className="p-6">
                                            <p className="text-sm text-gray-500">
                                                {article.date} · {article.author}
                                            </p>
                                            <h3 className="mt-2 text-xl font-semibold text-gray-800 hover:text-blue-600">
                                                {article.title}
                                            </h3>
                                            <p className="mt-4 text-gray-600">{article.description}</p>
                                            <span className="text-blue-600 mt-4 block font-medium hover:underline">
                        Read more
                      </span>
                                        </div>
                                    </a>
                                );
                            }

                            return (
                                <Link
                                    key={article.id}
                                    to={`/blogs/${article.id}`}
                                    className="block bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
                                >
                                    <img
                                        src={article.image}
                                        alt={article.title}
                                        className="w-full h-60 object-cover"
                                    />
                                    <div className="p-6">
                                        <p className="text-sm text-gray-500">
                                            {article.date} · {article.author}
                                        </p>
                                        <h3 className="mt-2 text-xl font-semibold text-gray-800 hover:text-blue-600">
                                            {article.title}
                                        </h3>
                                        <p className="mt-4 text-gray-600">{article.description}</p>
                                        <span className="text-blue-600 mt-4 block font-medium hover:underline">
                      Read more
                    </span>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </main>
            </div>
        </>
    );
};

export default BlogPage;
