import React from "react";
// Replace these imports with your actual images or correct paths:
import MyBroadbandLogo from "../assets/images/mybroadband-logo.png";
import Heading from "./heading";


const AsSeenOnSection: React.FC = () => {
    return (
        <section className="py-16 ">
            <div className="max-w-7xl mx-auto px-4 text-center">
                <Heading>As Seen On</Heading>
                <div className="flex flex-col sm:flex-row justify-center items-center gap-8 mt-8">
                    <a
                        href="https://mybroadband.co.za/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block hover:opacity-80 transition-opacity duration-200"
                    >
                        <img
                            src={"https://mybroadband.co.za/news/wp-content/themes/mybroadband/img/logo.svg"}
                            alt="MyBroadband"
                            className="h-16 mx-auto"
                        />
                    </a>
                    <a
                        href="https://techcentral.co.za/cirrus-bridge-consultancy-end-to-end/254300/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block hover:opacity-80 transition-opacity duration-200"
                    >
                        <img
                            src={"https://techcentral.co.za/wp-content/uploads/2018/08/techcentral-2156-1120.jpg"}
                            alt="TechCentral"
                            className="h-24 mx-auto"
                        />
                    </a>
                    <a
                        href="https://businesstech.co.za/news/industry-news/806521/cirrus-bridge-crafting-the-future-of-digital-consultancy-with-tailored-mobile-solutions/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block hover:opacity-80 transition-opacity duration-200"
                    >
                        <img
                            src={"https://businesstech.co.za/news/wp-content/themes/businesstech/img/logo.png"}
                            alt="BusinessTech"
                            className="h-16 mx-auto"
                        />
                    </a>
                </div>
            </div>
        </section>
    );
};

export default AsSeenOnSection;
